import React from 'react'

import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import RegisterForm from '../components/RegisterForm'
import Input from '../components/Input'
import ExplanationSection from '../components/ExplanationSection'

import '../scss/page-debates-for-schools.scss'

const schoolsPage = () => (
  <Layout>
    <Seo title="Debates for Schools | Talk of the Town" />

    <Hero text="Debates for Schools" />

    <section className="schools" id="debates-for-schools">
      <Container>
        <Row>
          <Col>
            <h2 className="subtitle">
              O melhor do nosso conteúdo para Escolas, Cursos e
              Universidades.
            </h2>
            <p className="text-center">
              Cada instituição contará com acesso ilimitado a um
              acervo de centenas de Debates in English, criados ao
              longo das últimas décadas e renovados semanalmente
              com os acontecimentos mais recentes do país e do
              mundo. Os temas são variados - história, ciências,
              tecnologia, artes, educação, comportamento - o que
              permite a professores de diversas disciplinas
              adaptar nosso material e enriquecer suas aulas, de
              forma criativa, à sua própria maneira. Nossa
              plataforma permite o login do professor e também de
              cada aluno, individualmente, para acesso e pesquisa
              do material estudado.
            </p>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg="3" className="box">
            <div className="wrapper">
              <p>
                <strong>
                  Ensino médio e Cursos Pré-Vestibular
                  </strong>
              </p>
              <p>
                Introduzir o Talk of the Town no currículo é uma
                maneira rápida e prática de modernizar e
                informatizar escolas mais tradicionais.
              </p>
            </div>
          </Col>
          <Col lg="3" className="box">
            <div className="wrapper">
              <p>
                <strong>Escola Bilingues</strong>
              </p>
              <p>
                Nosso conteúdo pode ser utilizado em diversas
                matérias, desde o Middle School, já que todas
                elas são ministradas em inglês.
              </p>
            </div>
          </Col>
          <Col lg="3" className="box">
            <div className="wrapper">
              <p>
                <strong>Cursos de Inglês</strong>
              </p>
              <p>
                Os debates do Talk of the Town podem funcionar
                como apoio às aulas de conversation, composition
                e também como um curso de extensão à parte,
                fidelizando alunos que concluem o curso regular
                e desejem continuar praticando o idioma.
              </p>
            </div>
          </Col>
          <Col lg="3" className="box">
            <div className="wrapper">
              <p>
                <strong>Faculdades e Universidades</strong>
              </p>
              <p>
                Nos cursos de Letras, matérias de inglês ou
                eletivas, os debates do Talk of the Town têm um
                enorme potencial de utilização.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <ExplanationSection
      imageBackground={require('../images/imagem_6.jpeg')}
      right
      className="schoolsContinued"
    >
      <h3>CADA DEBATE CONTÉM:</h3>
      <ul>
        <li>
          Texto em inglês sobre o tema estudado, nas versões do aluno
          e do professor
        </li>
        <li>Vocabulário do texto para o professor.</li>
        <li>
          Pesquisas de referências a pessoas, lugares, instituições,
          eventos encontrados no texto.
        </li>
        <li>Vídeos em inglês sobre o tema abordado.</li>
        <li>Roteiro de perguntas-chave para condução do debate </li>
        <li>
          Roteiro com sugestões de adaptação do nosso conteúdo a aulas
          presenciais e online de currículo regular em escolas
          bilingues, de ensino médio, cursos de inglês e
          universidades.
        </li>
      </ul>
    </ExplanationSection>
    <ExplanationSection
      imageBackground={require('../images/debates-for-schools-2.jpg')}
      left
      className="schoolsContinued"
    >
      <h3>PENSANDO EM INGLÊS: HABILIDADES PARA O SÉCULO XXI</h3>
      <p>
        Nossa metodologia de debates está em sintonia com as habilidades
        necessárias para a formação de profissionais e cidadãos do
        século XXI, que conviverão com a tecnologia, com a diversidade,
        com transformações constantes na maneira de viver, trabalhar e
        se relacionar. Um mundo onde o domínio do inglês é essencial,
        mas onde, mais do que nunca, é preciso saber pensar. Nossos
        debates são criados com este propósito: não temos as respostas
        certas, mas fazemos as perguntas certas para que cada aluno
        encontre suas próprias respostas.
      </p>
    </ExplanationSection>
    <section className="wrapperFormRegister">
      <h4>PARA OUTRAS INFORMAÇÕES, ENTRE EM CONTATO POR AQUI.</h4>
      <RegisterForm noSidebar>
        <Input
          name="registerName"
          placeholder="Nome completo"
          className="form-control"
          required
        />
        <Input
          name="registerInstution"
          placeholder="Instituição de Ensino"
          className="form-control"
          required
        />
        <Input
          name="registerRole"
          placeholder="Cargo"
          className="form-control"
          required
        />
        <Input
          name="registerEmail"
          placeholder="E-mail"
          type="email"
          className="form-control"
          required
        />
        <Input
          name="registerTelefone"
          placeholder="Telefone"
          className="form-control"
          required
        />
      </RegisterForm>
    </section>
  </Layout>
)

export default schoolsPage
